import axios from 'axios';
import store from './library/store';

const api = () => {
    let headers = {};

    if (store.get('access_token')) {
        headers['XAccessToken'] = store.get('access_token');
    }

    return axios.create({
        baseURL: '/api/v1/',
        headers,
    });
}


export const GetProfile = () => api().get('profile').then(res => res.data);
export const GetPositions = () => api().get('positions').then(res => res.data);
export const GetContacts = (id) => api().get(`profile/contacts/${id}`).then(res => res.data);