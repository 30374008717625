class M2Store {
    constructor() {
        this.local = (typeof window.localStorage === "undefined") ? false : true;
    }

    __setCookie(name, value, options) {
        options = options || {};
        let expires = options.expires;
        if (typeof expires === "number" && expires) {
            let d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }
        let updatedCookie = name + "=" + value;
        for (let propName in options) {
            updatedCookie += "; " + propName;
            let propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += "=" + propValue;
            }
        }
        document.cookie = updatedCookie;
        return true;
    }

    __getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    __removeCookie(name) {
        var options = {
            expires: - 1
        };
        return this.__setCookie(name, '', options);
    }

    __setLocal(name, value) {
        return window.localStorage.setItem(name, value);
    }

    __getLocal(name) {
        return window.localStorage.getItem(name);
    }

    __removeLocal(name) {
        return window.localStorage.removeItem(name);
    }

    set(name, value, options) {
        return this.local ? this.__setLocal(name, value) : this.__setCookie(name, value, options);
    }

    get(name) {
        return this.local ? this.__getLocal(name) : this.__getCookie(name);
    }

    remove(name) {
        return this.local ? this.__removeLocal(name) : this.__removeCookie(name);
    }
};

const store = new M2Store();
export default store;