export function createZoomControl() {
    const layout = window.ymaps.templateLayoutFactory.createClass(
        `<div class="domoy-map__zoom-controls">
                <button id='zoom-in' class='btn btn-blue'><svg><use xlink:href="#map-btn-plus"></use></svg></button>
                <button id='zoom-out' class='btn btn-blue'><svg><use xlink:href="#map-btn-minus"></use></svg></button>
          </div>`,
        {
            build: function () {
                this.constructor.superclass.build.call(this);

                this.zoomInCallback = window.ymaps.util.bind(this.zoomIn, this);
                this.zoomOutCallback = window.ymaps.util.bind(this.zoomOut, this);

                document
                    .querySelector("#zoom-in")
                    .addEventListener("click", this.zoomInCallback);
                document
                    .querySelector("#zoom-out")
                    .addEventListener("click", this.zoomOutCallback);
            },

            clear: function () {
                document
                    .querySelector("#zoom-in")
                    .removeEventListener("click", this.zoomInCallback);
                document
                    .querySelector("#zoom-out")
                    .removeEventListener("click", this.zoomOutCallback);

                this.constructor.superclass.build.call(this);
            },

            zoomIn: function () {
                var map = this.getData().control.getMap();
                map.setZoom(map.getZoom() + 1, { checkZoomRange: true });
            },

            zoomOut: function () {
                var map = this.getData().control.getMap();
                map.setZoom(map.getZoom() - 1, { checkZoomRange: true });
            },
        }
    );

    return new window.ymaps.control.ZoomControl({
        options: {
            layout,
            position: {
                left: "auto",
                right: window.innerWidth <= 768 ? 16 : 24,
                bottom: window.innerWidth <= 768 ? Math.ceil(window.innerHeight / 2) - 80: 128,
                top: "auto",
            },
        },
    });
}
