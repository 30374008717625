export function createSearchControl(MAP) {
    const layout = window.ymaps.templateLayoutFactory.createClass(
        `<div class="domoy-map__search-controls">
              <div class="map-search-input" style="display: none;">
                <input class="input" type="text" id="map_search" autofocus placeholder="Ваш запрос..."/>
              </div>
              <button id="search-button" class="btn btn-blue">
                <svg viewBox="0 0 24 24">
                  <use xlink:href="#map-btn-search"></use>
                </svg>
              </button>
              
        </div>`,
        {
            build: function () {
                const self = this;

                this.constructor.superclass.build.call(this);

                this.field = new window.ymaps.SuggestView("map_search", {
                    offset: [0, 0],
                    boundedBy: MAP.getBounds(),
                });
                this.field.events.add("select", this.FieldSelect, this);

                this._$searchInput = this.getParentElement().querySelector(
                    ".map-search-input"
                );
                this._$searchButton = this.getParentElement().querySelector(
                    ".btn"
                );

                function hideInput() {
                    self._$searchInput.style.display = "none";
                    document.removeEventListener("click", overflowHandler);
                }

                function overflowHandler(evt) {
                    if (!evt.target.closest(".domoy-map__search-controls")) {
                        hideInput();
                    }
                }



                this._$searchButton.addEventListener("click", () => {
                    if (this._$searchInput.style.display === 'flex') {
                        hideInput();
                    } else {
                        this._$searchInput.style.display = "flex";
                        document.getElementById("map_search").focus();
                        document.addEventListener("click", overflowHandler);
                    }

                });
            },

            clear: function () {
                this.field.events.remove("select", this.FieldSelect, this);
                this.constructor.superclass.clear.call(this);
            },

            FieldSelect: function (e) {
                window.ymaps.geocode(e.get("item").value).then(function (res) {
                    MAP.setBounds(res.geoObjects.get(0).properties.get("boundedBy"));
                });
            },
        }
    );

    const searchControl = new window.ymaps.control.SearchControl({
        options: {
            layout,
            position: {
                left: "auto",
                right: window.innerWidth <= 768 ? 16 : 24,
                top: window.innerWidth <= 768 ? 64 : 88,
                bottom: "auto",
            },
        },
    });

    return searchControl;
}
