import './sass/main.scss';
import {createSearchControl, createZoomControl, createPlacemark, createGeoControl} from "./components/map";
import AppStore from './library/store';
import { GetPositions, GetContacts } from './api';
import DOMPurify from "dompurify";


const MAP_SETTINGS = {
    center: [54.741571, 37.513802],
    zoom: 7,
    controls: [],
    behaviors: ["drag", "dblClickZoom", "multiTouch"],
};


const RenderContactType = (t) => {
    if (t.contact.indexOf('https://t.me/') + 1 > 0 || t.contact.indexOf('https://wa.me') + 1 > 0) {
        return (
            `<div class="popup-contact">
                    <a class="btn btn-social ${t.contact.indexOf('https://t.me/') + 1 > 0 ? `btn-telegram` : `btn-wh`}" href="${t.contact}" target="_blank" rel="noopener">${t.contact.indexOf('https://t.me/') + 1 > 0 ? `Telegram` : `Whatsapp`}</a>
            </div>
            `
        );
    }
   return ''
}


window.GetProfileContacts = async (e) => {
    const btn = e.target.classList.contains('btn-get-contacts') ? e.target : e.target.closest('.btn-get-contacts');
    if (btn) {
        if (btn.classList.contains('bntn-load') === false) {
            btn.classList.add('btn-load');
            try {
                let resp = await GetContacts(btn.getAttribute(`data-profile`));
                let template = ``;
                    template += '<div class="popup--title">Контакты</div>';
                    if (resp.contacts && resp.contacts.length > 0) {
                        resp.contacts.forEach(i => {
                            if (i.type === 'phone') {
                                template += `
                                    <div class="popup-contact">
                                        <a href="tel:${i.contact}" class="btn btn-blue">${i.contact}</a>
                                        <span>${DOMPurify.sanitize(i.comment)}</span>  
                                    </div>`;
                            } else {
                                template += RenderContactType(i);
                            }
                        });
                    }
                    let d = document.createElement('div');
                    d.classList.add('popup--contacts');
                    d.innerHTML = template
                btn.parentNode.insertBefore(d, btn)
                btn.remove();
            } catch(e) {
                console.error(`[GetContacts] `, e);
                btn.classList.remove('btn-load');
            }
        }

    }
}


const getAddress = (coords) => {
    return new Promise((resolve, reject) => {
        window.ymaps.geocode(coords).then((res) => {
            let firstGeoObject = res.geoObjects.get(0);
            resolve(firstGeoObject.properties ? firstGeoObject.properties._data.text : '');
        }).catch(e => {
            reject({error: true, e});
        });
    });
};


const GetContactTemplate = () => {
    let div = document.createElement('div');
    div.classList.add(`contacts--block`);
    div.innerHTML = ` <div class="contact-column">
                                <span>Ссылка на соц. сети / мессенджер</span>
                                <input class="input form-input" type="tel" name="contact[]"/>
                            </div>
                            <div class="contact-column">
                                <span>Комментарий к контакту</span>
                                <input class="input form-input" type="tel" name="contact_comment[]"/>
                            </div>
                            <div class="contact-column">
                                <span></span>
                                <button class="btn btn-add-contact del-contact" type="button">
                                    <svg><use xlink:href="#btn-close"></use></svg>
                                </button>
                            </div>`
    return div;
}

const buildPlacemark = (center, address) => {
    if (window.userPlacemark) {
        window.mapCreate.geoObjects.remove(window.userPlacemark);
    }
    window.userPlacemark = new window.ymaps.Placemark(center, {
        hintContent: `${address}`
    }, {
        iconLayout: 'default#image',
        iconImageHref: 'https://cdn.rt.com/map/geo_point2.png',
        iconImageSize: [32, 32],
        iconImageOffset: [-16, -16],
        draggable: true,
    });
    window.userPlacemark.events.add('dragend', async (e) => {
        let coords = window.userPlacemark.geometry.getCoordinates();
        try {
            address = await getAddress(coords);
            document.querySelector('input[name="lat"]').value = coords[0];
            document.querySelector('input[name="lng"]').value = coords[1];
            document.querySelector('input[name="address_location"]').value = address;
        } catch (e) {
            console.error(`failed to load coords: `, e);
        }
    });

    window.mapCreate.geoObjects.add(window.userPlacemark);
    document.querySelector('input[name="address_location"]').value = address;
    document.querySelector('input[name="lat"]').value = center[0];
    document.querySelector('input[name="lng"]').value = center[1];
}


window.addEventListener('DOMContentLoaded', async () => {

    const menuBtn = document.querySelector('.menu__mobile-trigger');
    const menuMobile = document.querySelector('.mobile--menu');
    if (menuBtn) {
        menuBtn.addEventListener('click', () => {
            const wrp = menuBtn.querySelector('.trigger__wrapper');
            if (wrp.classList.contains('open')) {
                wrp.classList.remove('open')
                menuMobile.classList.remove('visible')
            } else {
                wrp.classList.add('open')
                menuMobile.classList.add('visible')
            }
        })
    }


    if ((window.innerWidth <= 768 || window.innerHeight <= 768) && document.getElementById('map')) {
        //hack
        let interval = setInterval(() => {
            if (window.OPEN_PLACEMARKS && window.OPEN_PLACEMARKS.balloon.isOpen()) {
                document.querySelector('.map--footer').style.display = 'none';
            } else {
                document.querySelector('.map--footer').style.display = 'flex';
            }
        }, 100);
    }

    document.addEventListener('click', (e) => {
        if (e.target.classList.contains('btn-get-contacts') || e.target.closest('.btn-get-contacts')) {
            window.GetProfileContacts(e)
        }

        if (e.target.classList.contains('btn-close-modal') || e.target.closest('.btn-close-modal')) {
            if (window.OPEN_PLACEMARKS) {
                window.OPEN_PLACEMARKS.balloon.close();
            }
        }

        if (e.target.classList.contains('address--header') || e.target.closest('.address--header')) {
            let add = e.target.closest('.address');
            if (add.classList.contains('visible')) {
                add.classList.remove('visible');
            } else {
                add.classList.add('visible');
            }
        }

    });


    if (document.getElementById('map')) {
        window.ymaps.ready(async () => {
            window.map = new window.ymaps.Map('map', MAP_SETTINGS, {
                suppressMapOpenBlock: 'true',
            });
            window.map.controls.add(createZoomControl());
            window.map.controls.add(createSearchControl(window.map));
            createGeoControl(window.map);

            // load positions
            try {
                let resp = await GetPositions();
                window.OPEN_PLACEMARKS = null;
                if (resp && resp.list) {
                    let mapCluster = [];
                    let placemarks_groups = {};

                    resp.list.forEach(point => {
                        let coords_index = `${point.address.lat}_${point.address.lng}`;
                        if (!placemarks_groups[coords_index]) {
                            placemarks_groups[coords_index] = [];
                        }
                        placemarks_groups[coords_index].push(point);
                    });

                    for(let i in placemarks_groups) {
                        if (placemarks_groups[i].length > 1) {
                            let lg = placemarks_groups[i].length;
                            placemarks_groups[i].forEach((i, index) => {
                                let point = {...i};
                                let orientation = index % 2 === 0;
                                let offset = (orientation ? -1 : 1) * (index + lg);
                                point.address.lat += parseFloat(`0.00${offset}`);
                                point.address.lng += parseFloat(`0.00${offset}`);
                                let placemark = createPlacemark(point);
                                placemark.events.add("click", (e) => {
                                    window.OPEN_PLACEMARKS = e.get("target");
                                    window.map.setCenter(e.get("coords"));
                                    window.map.setZoom(13);
                                });
                                window.map.geoObjects.add(placemark);
                                // mapCluster.push(placemark)
                            });

                        } else {
                            let placemark = createPlacemark(placemarks_groups[i][0]);
                            placemark.events.add("click", (e) => {
                                window.OPEN_PLACEMARKS = e.get("target");
                                window.map.setCenter(e.get("coords"));
                                window.map.setZoom(13);
                            });
                            // mapCluster.push(placemark)
                            window.map.geoObjects.add(placemark);
                        }
                    }


                    window.map.events.add("click", (e) => {
                        if (
                            window.OPEN_PLACEMARKS &&
                            window.OPEN_PLACEMARKS.balloon &&
                            window.OPEN_PLACEMARKS.balloon.isOpen()
                        ) {
                            window.OPEN_PLACEMARKS.balloon.close();
                        }
                    });
                }
            } catch (e){
                console.error(`[Error] failed to load positions`);
            }
        });
    }

    const regModal = document.getElementById('registration-modal');
    const createBtn = document.getElementById('btn-create');
    if (createBtn) {
        createBtn.addEventListener('click', function (e) {
            e.preventDefault();
            if (regModal) {
                regModal.classList.add('visible');
            } else {
                window.location.href = '/create';
            }
        });
    }

    /* modals */
    const btns = [].slice.call(document.querySelectorAll('[btn-modal]'))
    const modals = [].slice.call(document.querySelectorAll('.modal'))
    if (btns) {
        btns.forEach(i => {
            i.addEventListener('click', (e) => {
                e.preventDefault();
                let modalId = i.getAttribute('btn-modal');
                let modal = document.getElementById(modalId);
                if (modal) {
                    modal.classList.add('visible');
                }
            })
        })
    }

    window.addEventListener('keydown', (e) => {
        if (e.keyCode === 27 || e.key === 'Escape') {
            modals.forEach(i => {
                if (i.classList.contains('visible')) {
                    i.classList.remove('visible');
                }
            });
        }
    });

    if (modals) {
        modals.forEach(i => {
            i.addEventListener('click', (e) => {

                if (e.target.classList.contains('modal') || e.target.classList.contains('btn-close') || e.target.closest('.btn-close')) {
                    i.classList.remove('visible');
                }
            })
        });
    }


    if (document.getElementById('profile-page')) {
        const addContactBtn = document.getElementById('add-content');
        addContactBtn.addEventListener('click', () => {
            addContactBtn.parentNode.insertBefore(GetContactTemplate(), addContactBtn);
        });

        document.getElementById('profile-page').addEventListener('click', (e) => {
            if (e.target.classList.contains('del-contact') || e.target.closest('.del-contact')) {
                e.target.closest('.contacts--block').remove();
            }
        })
    }


    if (document.getElementById('create-app')) {
        // counters
        const btnsCounter = [].slice.call(document.querySelectorAll('.counter_actions button'));
        if (btnsCounter) {
            btnsCounter.forEach(i => {
                i.addEventListener('click', (e) => {
                    let inputValue = e.target.closest('.counter_actions').querySelector('.input-counter');
                    if (inputValue) {
                        let v = parseInt(inputValue.value, 10);
                        if (e.target.classList.contains('btn-minus') || e.target.closest('.btn-minus')) {
                            v = v - 1;
                            if (v < 0) {
                                v = 0;
                            }

                        }
                        if (e.target.classList.contains('btn-plus') || e.target.closest('.btn-plus')) {
                            v = v + 1;
                        }

                        const btnMinus = e.target.classList.contains('btn-minus')  ? e.target : e.target.closest('.counter_actions').querySelector('.btn-minus');
                        if (v === 0) {
                            btnMinus.classList.remove('btn-blue');
                            btnMinus.classList.add('btn-grey');
                        } else {
                            btnMinus.classList.add('btn-blue');
                            btnMinus.classList.remove('btn-grey');
                        }
                        inputValue.value = v;
                    }
                });
            })
        }

        //map-create
        window.userPlacemark = null;
        window.ymaps.ready(async () => {
            let suggest = new window.ymaps.SuggestView('suggest');
            suggest.events.add('select', (e) => {
                if(e.get('item')) {
                    let item = e.get('item');
                    window.ymaps.geocode(item.value).then((res) => {
                        let obj = res.geoObjects.get(0);

                        if (obj && obj.geometry && obj.geometry._coordinates) {
                            let coords = obj.geometry._coordinates;
                            window.mapCreate.setCenter(coords);
                            window.mapCreate.setZoom(14);
                            window.mapCreate.geoObjects.removeAll();
                            buildPlacemark(coords, item.value)
                        }
                    }).catch(e => {
                        console.error(`failed to load coords: `, e);
                    });
                }
            })

            let mapSettings = {...MAP_SETTINGS};
            let setPoint = false;


            if (document.querySelector('input[name="lat"]') && document.querySelector('input[name="lat"]').value) {
                if (parseFloat(document.querySelector('input[name="lat"]').value)) {
                    mapSettings.center = [parseFloat(document.querySelector('input[name="lat"]').value), parseFloat(document.querySelector('input[name="lng"]').value)]
                    mapSettings.zoom = 14;
                    setPoint = true;
                }
            }

            window.mapCreate = new window.ymaps.Map('map-create', mapSettings, {
                suppressMapOpenBlock: 'true',
            });

            if (setPoint) {
                console.log(mapSettings)
                buildPlacemark(mapSettings.center, document.querySelector('input[name="address_location"]').value)
            }


            // click event
            window.mapCreate.events.add('click', async (e) => {
                try {
                    let coords = e.get('coords');
                    let address = await getAddress(coords);
                    window.mapCreate.setCenter(coords);
                    window.mapCreate.setZoom(14);
                    window.mapCreate.geoObjects.removeAll();
                    buildPlacemark(coords, address)
                } catch (e) {
                    console.error(`failed to load coords: `, e)
                }
            });
        });

        const geoBtn = document.getElementById('get-geo');
        if (geoBtn) {
            geoBtn.addEventListener('click', () => {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    if (position && position.coords) {
                        let center = [position.coords.latitude, position.coords.longitude];
                        let address = {};
                        try {
                            address = await getAddress(center);
                            window.mapCreate.setCenter(center);
                            window.mapCreate.setZoom(12);
                            window.mapCreate.geoObjects.removeAll();
                            buildPlacemark(center, address)
                        } catch(e) {
                            console.error(`failed to load address: `, e)
                        }
                    }
                });
            })

        }
    }

});


window.SetAccessToken = (token) => {
    // AppStore.__setCookie('dom_token', token, {
    //     expires: 3153600,
    // });
    AppStore.set('access_token', token);

}