import DOMPurify from 'dompurify';
import { createHint } from './hint';


const createLayout = () =>
    window.ymaps.templateLayoutFactory.createClass(
        "<div class='vmmap-popup color_$[properties.balloonColor]'>" +
        "<div class='popup-wrp'>" +
            "$[[options.contentLayout observeSize minWidth=300 maxWidth=336 maxHeight=500]]" +
        "</div>" +
        "<div class='arrow'></div>" +
        "</div>",
        {
            build: function () {
                this.constructor.superclass.build.call(this);
                this._$element = this.getParentElement().querySelector(".vmmap-popup");
                this.applyElementOffset();
            },

            applyElementOffset: function () {
                this._$element.style.setProperty(
                    "left",
                    `${-(this._$element.offsetWidth / 2)}px`
                );
                this._$element.style.setProperty(
                    "top",
                    `${-(
                        this._$element.offsetHeight +
                        this._$element.querySelector(".arrow").offsetHeight +
                        40
                    )}px`
                );
            },

            clear: function () {
                this.constructor.superclass.clear.call(this);
            },

            onSublayoutSizeChange: function () {
                this.constructor.superclass.onSublayoutSizeChange.apply(
                    this,
                    arguments
                );
                if (!this._isElement(this._$element)) {
                    return;
                }
                this.applyElementOffset();
                this.events.fire("shapechange");
            },

            _isElement: function (element) {
                return element && element.querySelector(".arrow");
            },

            getShape: function () {
                if (!this._isElement(this._$element)) {
                    return this.constructor.superclass.getShape.call(this);
                }

                return new window.ymaps.shape.Rectangle(
                    new window.ymaps.geometry.pixel.Rectangle([
                        [
                            -this._$element.offsetWidth / 2,
                            -this._$element.offsetHeight - 40,
                        ],
                        [this._$element.offsetWidth / 2, this._$element.offsetHeight],
                    ])
                );
            },
        }
    );

const createLayoutContent = () =>
    window.ymaps.templateLayoutFactory.createClass(
        '<div class="vmpopup-content">$[properties.balloonHeader]</div>'
    );

export function createPlacemark(point) {
    return new window.ymaps.Placemark(
        [point.address.lat, point.address.lng],
        {
            object: point.address.location,
            balloonHeader: renderAdventureTemplate(point),
            balloonImage: getCover(point.profile),
            balloonColor: "0055ff",
        },
        {
            hintLayout: createHint(),
            iconLayout: "default#image",
            iconImageHref: "https://cdn.rt.com/map/geo_point2.png",
            iconImageSize: [32, 32],
            iconImageOffset: [-16, -16],
            balloonShadow: false,
            balloonLayout: createLayout(),
            balloonContentLayout: createLayoutContent(),
            balloonPanelMaxMapArea: 0,
            hideIconOnBalloonOpen: false,
            balloonOffset: [0, 24],
        }
    );
}

function renderAdventureTemplate(point) {
    if (!point) return ``;
    let template = ``;

    template = `<div class="vmpopup_materials">`;
        template += `<div class="vmpopup_material">`;
            template += '<div class="vmpopup_modal__title"><span>' + point.type + '</span><button class="btn btn-close-modal"><svg><use xlink:href="#btn-close"></use></svg></button></div>';
            template += '<div class="vmpopup_cover">' + getCover(point.profile) + "</div>";
            template += `<div class="vmpopup_info">`;
                template += '<div class="popup--title">Количество человек</div>';
                template += `<div class="persons-count">`
                    template += `<div class="count-item"><span class="label">Взрослые</span><span class="value">${point.adult_count}</span></div>`;
                    template += `<div class="count-item"><span class="label">Дети</span><span class="value">${point.child_count}</span></div>`;
                    template += `<div class="count-item"><span class="label">Младенцы</span><span class="value">${point.baby_count}</span></div>`;
                template += '</div>';
                template += '<div class="popup--title">Описание жилья</div>';
                template += `<div class="popup--description" title="${DOMPurify.sanitize(point.description)}">${getSummary(DOMPurify.sanitize(point.description))}</div>`;
                template += '<div class="popup--title">Адрес</div>';
                template += `<div class="popup--description popup--address">${DOMPurify.sanitize(point.address.location)}</div>`;
                template += '<div class="btns">';
                    template += `<a class="btn btn-grey" target="_blank" rel="noreferrer" href="https://yandex.ru/maps/?rtext=~${point.address.lat},${point.address.lng}">Построить маршрут</a>`;
                    template += `<button class="btn btn-blue btn-get-contacts" data-profile="${point.profile.id}">Показать контакты</button>`;
                template += '</div>';
            template += `</div>`;
        template += `</div>`;
    template += `</div>`;

    return template;
}

function getAdventureType(d) {
    if (d.adventureType) {
        return `<p class="type">
        <a href="${d.adventureType.href}">${d.adventureType.title}</a>
      </p>`;
    }

    return "";
}

function getSummary(d) {
    return d
        ? `<p class="summary">${
            d.length > 250 ? d.slice(0, 250) + "..." : d
        }</p>`
        : "";
}

function getCover(profile) {
    if (profile) {
        return `
                <div class="user-header__profile">
                    <div class="user--profile">
                        <img src="https://dom.rt.com/profiles/avatars/${profile.avatar}" alt="user profile" />
                        <div class="user--profile__info">
                             <span class="first_name">${profile.first_name}</span>
                             <span class="last_name">${profile.last_name}</span>
                        </div>
                    </div>
                </div>
                
        `;
    }

    return "";
}
