export function createGeoControl(map) {
    const button = new window.ymaps.control.Button({
        data: {},
        options: {
            layout: window.ymaps.templateLayoutFactory.createClass(
                "<button class='btn btn-blue' id='current_geo'><svg><use xlink:href=\"#geo\"></use></svg></div>"
            ),
            maxWidth: 40
        }
    });

    button.events.add('click', async () => {
        navigator.geolocation.getCurrentPosition((position) => {
            if (position && position.coords) {
                let center = [position.coords.latitude, position.coords.longitude];
                map.setCenter(center);
                map.setZoom(12);
            }
        });
    });
    map.controls.add(button, {
        position: {
            right: window.innerWidth <= 768 ? 16 : 24,
            top: window.innerWidth <= 768 ? 104 : 134,
        }
    });
}
