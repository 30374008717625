export function createHint() {
    return window.ymaps.templateLayoutFactory.createClass(
        "<div class='vmhint' style='white-space: pre-wrap'>" +
        "{{ properties.object }}" +
        "</div>",
        {
            getShape: function () {
                let el = this.getElement(),
                    result = null;
                if (el) {
                    let firstChild = el.firstChild;

                    result = new window.ymaps.shape.Rectangle(
                        new window.ymaps.geometry.pixel.Rectangle([
                            [0, 0],
                            [firstChild.offsetWidth + 24, firstChild.offsetHeight + 24],
                        ])
                    );
                }

                return result;
            },
        }
    );
}
